<template>
  <van-popup
    class="app-speech-modal"
    v-model="visible"
    round
    position="bottom"
    :close-on-click-overlay="false"
  >
    <div v-if="voiceState === 'recording'" class="circle-wrapper">
      <x-circle
        :percent="percent"
        :stroke-width="6"
        :trail-width="6"
        :transition-time="20"
        :stroke-color="['#36D1DC', '#5B86E5']"
        trail-color="#ececec"
      >
        <div class="loading">
          <span :style="{ height: waves[9] + 'px' }"></span>
          <span :style="{ height: waves[8] + 'px' }"></span>
          <span :style="{ height: waves[7] + 'px' }"></span>
          <span :style="{ height: waves[6] + 'px' }"></span>
          <span :style="{ height: waves[5] + 'px' }"></span>
          <span :style="{ height: waves[4] + 'px' }"></span>
          <span :style="{ height: waves[3] + 'px' }"></span>
          <span :style="{ height: waves[2] + 'px' }"></span>
          <span :style="{ height: waves[1] + 'px' }"></span>
          <span :style="{ height: waves[0] + 'px' }"></span>
          <span :style="{ height: waves[1] + 'px' }"></span>
          <span :style="{ height: waves[2] + 'px' }"></span>
          <span :style="{ height: waves[3] + 'px' }"></span>
          <span :style="{ height: waves[4] + 'px' }"></span>
          <span :style="{ height: waves[5] + 'px' }"></span>
          <span :style="{ height: waves[6] + 'px' }"></span>
          <span :style="{ height: waves[7] + 'px' }"></span>
          <span :style="{ height: waves[8] + 'px' }"></span>
          <span :style="{ height: waves[9] + 'px' }"></span>
        </div>
      </x-circle>
    </div>
    <div v-if="voiceState === 'recognizing'" class="voice-loading">
      <van-loading color="#1989fa" size="48" vertical></van-loading>
    </div>
    <div class="voice-text">{{ keyword }}</div>
    <div class="mic-wrapper">
      <van-icon class="voice-icon" class-prefix="iconfont" name="mic" />
    </div>
  </van-popup>
</template>
<script>
import _, { debounce, throttle } from 'lodash'
import XCircle from '@/components/charts/Circle'
import { mapState } from 'vuex'
// import { Toast } from 'vant'
// import { mapState } from 'vuex'

export default {
  name: 'AppSpeechModal',
  components: {
    XCircle
  },
  data () {
    return {
      visible: false,
      // 'waiting', 'recording', 'recognizing', 'recognized'
      voiceState: 'recognized',
      delayWaiting: false,
      percent: 0,
      strokeWidth: 6,
      trailWidth: 6,
      strokeColor: ['#36D1DC', '#5B86E5'],
      trailColor: '#ececec',
      recordedTime: 0,
      intervalObj: null,
      timeoutObj: null,
      speechTexts: [],
      waves: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      isiOS: false
    }
  },
  computed: {
    ...mapState({
      speechControl: state => state.speech.speechControl,
      deviceType: state => state.global.deviceType
    }),
    keyword () {
      if (this.speechTexts.length === 0) {
        return '正在聆听…'
      } else {
        return this.speechTexts.join('')
      }
    }
  },
  created: function () {
    this.isiOS = this.deviceType === 'ios'
    if (this.isiOS) {
      const r = window.plus.audio.getRecorder()
      r.record({}, () => {
        r.stop()
      })
      window.plus.speech.addEventListener('start', () => {
        console.log('开始识别……')
        this.speechTexts = []
      }, false)
      window.plus.speech.addEventListener('volumeChange', (e) => {
        this.onAppSpeechVolumeChanged(e.volume * 65)
      }, false)
      window.plus.speech.addEventListener('recognizing', (e) => {
        console.log('正在识别……', JSON.stringify(e))
        this.speechTexts = []
        this.speechTexts.push(e.partialResult)
      }, false)
      window.plus.speech.addEventListener('recognition', (e) => {
        console.log('识别结果', JSON.stringify(e))
        this.onAppSpeechResult(e.result, 'replace')
      }, false)
      window.plus.speech.addEventListener('end', () => {
        console.log('识别结束')
        this.onAppSpeechEnd()
      }, false)
    }
  },
  methods: {
    initState () {
      this.percent = 0
      this.recordedTime = 0
      clearInterval(this.intervalObj)
      this.intervalObj = null
    },
    /**
     * 外部调用
     */
    waitingStartRecord () {
      // Toast('waitingStartRecord')
      this.delayWaiting = true
      this.percent = 0
      this.startRecordDelay()
    },
    startRecord () {
      if (!this.delayWaiting) return
      // Toast('startRecord')
      if (this.isiOS) {
        this.initState()
        setTimeout(() => {
          this.startTimeout()
          window.plus.speech.startRecognize({ engine: 'baidu', punctuation: false, userInterface: false })
        }, 200)
      } else {
        this.speechTexts.splice(0)
        this.waves.splice(0)
        window['XbiApp']['startSpeechListening']()
      }
    },
    startTimeout () {
      // Toast('startTimeout')
      this.voiceState = 'recording'
      this.visible = true
      this.recordedTime = 0
      this.intervalObj = setInterval(() => {
        // console.log('intervalObj: ' + this.recordedTime)
        if (this.percent < 100) this.percent = 100
        if (this.recordedTime >= 20) {
          this.endRecord()
        } else {
          this.recordedTime++
        }
      }, 1000)
    },
    /**
     * 外部调用
     */
    endRecord () {
      this.delayWaiting = false
      if (this.visible) {
        this.initState()
      } else {
        return
      }
      this.voiceState = 'recognizing'
      if (!this.isiOS) window['XbiApp']['stopSpeechListening']()
      this.endSpeechAndSearchDalay()
    },
    endSpeechAndSearch () {
      clearTimeout(this.timeoutObj)
      this.timeoutObj = null
      this.voiceState = 'recognized'
      if (this.keyword.indexOf('进入主控状态') > -1) {
        this.$store.dispatch('speech/setSpeechControl', true)
        this.$router.push({ name: 'SpeechControl' })
      } else if (this.keyword.indexOf('退出主控状态') > -1) {
        this.$store.dispatch('speech/setSpeechControl', false)
        this.$router.push({ name: 'Ai' })
        // this.$router.push({ name: 'Speech' })
      } else if (this.speechControl) {
        this.$eventBus.$emit('SPEECH_CONTROL_KEYWORD', this.keyword)
      } else {
        const keyword = this.keyword === '正在聆听…' ? '' : this.keyword
        if (this.$route.name === 'Ai') {
          this.$eventBus.$emit('SPEECH_VOICE_KEYWORD', keyword)
        } else {
          this.$store.commit('speech/setSpeechRecognizedKeyword', keyword)
          this.$router.push({ name: 'Ai' })
        }
        // if (this.$route.name === 'Speech') {
        //   this.$eventBus.$emit('SPEECH_VOICE_KEYWORD', this.keyword)
        // } else {
        //   this.$store.commit('speech/setSpeechRecognizedKeyword', this.keyword)
        //   this.$router.push({ name: 'Speech' })
        // }
      }
      this.hideSpeechModalDelay()
    },
    hideSpeechModal () {
      clearTimeout(this.timeoutObj)
      this.visible = false
    },
    onAppSpeechBegin () {
      this.startTimeout()
    },
    onAppSpeechEnd () {
      this.endRecord()
    },
    onAppSpeechResult (text, mode) {
      if (mode === 'replace') this.speechTexts.splice(0)
      this.speechTexts.push(text)
    },
    onAppSpeechVolumeChanged(volume) {
      this.addWave(volume)
    },
    addWave(i) {
      if (i === 0) i = _.random(3, 6)
      this.waves.unshift(i / 30 * 36)
      if (this.waves.length > 10) this.waves.splice(9)
    }
  },
  mounted () {
    this.startRecordDelay = debounce(this.startRecord, 200)
    this.hideSpeechModalDelay = debounce(this.hideSpeechModal, 1000)
    this.endSpeechAndSearchDalay = debounce(this.endSpeechAndSearch, 1000)
    this.addWaveDelay = throttle(this.addWave, 50)
    window.onAppSpeechBegin = this.onAppSpeechBegin
    window.onAppSpeechEnd = this.onAppSpeechEnd
    window.onAppSpeechResult = this.onAppSpeechResult
    window.onAppSpeechVolumeChanged = this.onAppSpeechVolumeChanged
  }
}
</script>

<style lang="less" scoped>
.app-speech-modal {
  height: 35%;
  pointer-events: none;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  .circle-wrapper {
    width: 6em;
    height: 6em;
    .voice-state {
      color: #333;
    }

    .loading {
      width: 80%;
      height: 3em;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
    .loading span {
      display: inline-block;
      width: 3px;
      margin: 0 1px;
      //height: 100%;
      min-height: 3px;
      max-height: 36px;
      border-radius: 4px;
      background: #36d1dc;
      align-self: center;
      transition: 200ms;
      //animation: load 800ms ease infinite;
    }
    @keyframes load {
      0%,
      100% {
        height: 60%;
        background: #36d1dc;
      }
      50% {
        height: 100%;
        background: #5b86e5;
      }
    }
    .loading span:nth-child(2) {
      animation-delay: 0.2s;
    }
    .loading span:nth-child(3) {
      animation-delay: 0.4s;
    }
    .loading span:nth-child(4) {
      animation-delay: 0.6s;
    }
    .loading span:nth-child(5) {
      animation-delay: 0.8s;
    }
  }
  .voice-loading {
    height: 6em;
    text-align: center;
    font-size: 0.75em;
    color: #888;
    line-height: 2em;
  }
  .voice-text {
    padding: 1em;
    line-height: 1.5em;
    text-align: center;
    color: #eee;
  }
  .mic-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    .voice-icon {
      font-size: 2em;
      line-height: 2em;
      color: #ccc;
    }
  }
}
</style>
