<template>
  <div class="home">
    <router-view/>
    <template v-if="!hideTabBar">
      <van-tabbar class="main-tabbar" v-model="active" route>
        <van-tabbar-item replace to="/index" icon="wap-home-o">主页</van-tabbar-item>
        <van-tabbar-item replace to="/dashboard/list" icon="chart-trending-o">仪表盘</van-tabbar-item>
        <van-tabbar-item
          class="speech-icon"
          @click="onSpeechClick">
          <template #icon>
            <van-icon
              class="voice-icon"
              :class="{ disabled: inWx && !wxReady}"
              class-prefix="iconfont"
              name="mic"
              @touchstart="$refs.speechModal.waitingStartRecord()"
              @touchend="$refs.speechModal.endRecord()" />
          </template>
          <span :class="{'van-tabbar-item--active': active === 2}">搜索</span>
        </van-tabbar-item>
        <van-tabbar-item replace to="/message/index" icon="chat-o" :badge="unreadNum===0?'': unreadNum">消息</van-tabbar-item>
        <van-tabbar-item replace to="/user" icon="user-o">设置</van-tabbar-item>
      </van-tabbar>
      <!--      <speech-modal ref="speechModal"></speech-modal>-->
      <app-speech-modal ref="speechModal" />
    </template>
  </div>
</template>

<script>
import SpeechModal from '../components/speech/SpeechModal'
import AppSpeechModal from '../components/speech/AppSpeechModal'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    SpeechModal,
    AppSpeechModal
  },
  data () {
    return {
      active: 0,
      unreadNum: 0
    }
  },
  computed: {
    ...mapState({
      hideTabBar: state => state.global.hideTabBar,
      wxReady: state => state.global.wxReady,
      inWx: state => state.global.inWx
    }),
    navTitle () {
      switch (this.active) {
        case 0: return '主页'
        case 1: return '仪表盘'
        case 2: return '搜索'
        case 3: return '消息'
        case 4: return '设置'
        default: return ''
      }
    }
  },
  methods: {
    onSpeechClick () {
      if (this.$route.path !== '/ai') {
        this.$router.replace('/ai')
      }
      // if (this.$route.path !== '/speech') {
      //   this.$router.replace('/speech')
      // }
    },
    setDefaultPage () {
      this.$api.user.getSettings().then(res => {
        if (res && res['settings'] && res['settings']) {
          const settings = JSON.parse(res['settings'])
          const mobileSettings = settings['mobile'] || {}
          if (mobileSettings.dataRegionId !== '') {
            this.$store.commit('dataRegion/setActiveId', mobileSettings.dataRegionId)
          }
          this.$store.commit('user/setSettings', settings)
          if (mobileSettings.defaultPageType === '搜索页面') {
            this.active = 2
            this.$router.replace({ name: 'Speech', params: { keyword: mobileSettings.extraParam } })
          }
        }
      }).catch((e) => {})
    }
  },
  mounted() {
    if (window['XbiApp']) {
      this.$store.dispatch('setStatusBarHeight', window['XbiApp']['getStatusBarHeight']())
    }
    // console.log('getStatusBarHeight', x)
    this.$eventBus.$on('unreadNum', unreadNum => {
      this.unreadNum = unreadNum
    })
    this.$eventBus.$on('reduceUnreadNum', () => {
      this.unreadNum = this.unreadNum - 1
    })
  },
  created () {
    this.setDefaultPage()
  },
  destroyed() {
    this.$eventBus.$off('unreadNum')
    this.$eventBus.$off('reduceUnreadNum')
  },
  watch: {
    unreadNum () {
      if (window['XbiApp']) {
        window['XbiApp']['setAppBadge'](this.unreadNum)
      }
    }
  }
}
</script>
<style lang="less">
  .home {
    .main-tabbar {
      z-index: 9;
    }
    .speech-icon {
      position: relative;
      z-index: 2;
      .van-tabbar-item__icon {
        height: 1.4em;
        width: 100%;
        margin-bottom: 0;
      }
      &:after {
        content: "";
        position: absolute;
        width: 4em;
        height: 4em;
        top: -1.4em;
        left: 0;
        right: 0;
        margin: auto;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4em;
        background-color: #ffffff;
        z-index: 0;
      }
      .voice-icon {
        position: absolute;
        width: 1.5em;
        z-index: 2;
        height: 1.5em;
        border-radius: 50%;
        line-height: 38px;
        font-size: 1.4em;
        top: -0.73em;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0;
        background-color: #0081ff;
        color: #ffffff;
        text-align: center;
        &.disabled {
          background-color: #646566;
        }
      }
    }
  }
</style>
