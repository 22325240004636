<template>
  <van-popup
    class="speech-modal"
    v-model="visible"
    round
    position="bottom"
    :close-on-click-overlay="false"
    :style="{ height: '30%' }">
    <div v-if="voiceState === 'recording'" class="circle-wrapper">
      <x-circle
        :percent="percent"
        :stroke-width="6"
        :trail-width="6"
        :transition-time="20"
        :stroke-color="['#36D1DC', '#5B86E5']"
        trail-color="#ececec">
        <div class="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </x-circle>
    </div>
    <div v-if="voiceState === 'recognizing'" class="voice-loading">
      <van-loading color="#1989fa" size="48" vertical>正在解析</van-loading>
    </div>
    <div v-if="voiceState === 'recognized'" class="voice-text">{{ keyword }}</div>
    <div class="mic-wrapper">
      <van-icon class="voice-icon" class-prefix="iconfont" name="mic" />
    </div>
  </van-popup>
</template>
<script>
import debounce from 'lodash/debounce'
import XCircle from '@/components/charts/Circle'
import { Toast } from 'vant'
import { mapState } from 'vuex'

export default {
  name: 'SpeechModal',
  components: {
    XCircle
  },
  data () {
    return {
      visible: false,
      // 'waiting', 'recording', 'recognizing', 'recognized'
      voiceState: 'recognized',
      delayWaiting: false,
      percent: 0,
      strokeWidth: 6,
      trailWidth: 6,
      strokeColor: ['#36D1DC', '#5B86E5'],
      trailColor: '#ececec',
      keyword: '',
      recordedTime: 0,
      intervalObj: null,
      timeoutObj: null
    }
  },
  computed: {
    ...mapState({
      wxReady: state => state.global.wxReady,
      inWx: state => state.global.inWx
    })
  },
  mounted () {
    this.startRecordDelay = debounce(this.startRecord, 600)
    this.hideSpeechModalDelay = debounce(this.hideSpeechModal, 1000)
  },
  methods: {
    initState () {
      this.percent = 0
      this.recordedTime = 0
      clearInterval(this.intervalObj)
      this.intervalObj = null
    },
    waitingStartRecord () {
      // Toast('waitingStartRecord');
      this.delayWaiting = true
      this.percent = 0
      this.startRecordDelay()
    },
    startRecord () {
      // Toast('startRecord: ' + JSON.stringify([this.delayWaiting && !this.wxReady]));
      if (!this.delayWaiting || !this.wxReady) return
      this.voiceState = 'recording'
      this.visible = true
      try {
        this.$wechat.startRecord({})
        this.recordedTime = 0
        this.intervalObj = setInterval(() => {
          console.log('intervalObj: ' + this.recordedTime)
          if (this.percent < 100) this.percent = 100
          if (this.recordedTime >= 20) {
            this.endRecord()
          } else {
            this.recordedTime++
          }
        }, 1000)
      } catch (e) {
        // Toast(JSON.stringify(['stopRecord0', e]));
        console.log(e)
        Toast('听音启动失败，确认已允许听音')
      }
    },
    endRecord () {
      // Toast('endRecord');
      this.delayWaiting = false
      if (this.visible) {
        this.initState()
      } else {
        return
      }
      this.voiceState = 'recognizing'
      this.$wechat.stopRecord({
        success: res => {
          if (res.localId) {
            this.startRecognize(res.localId)
          } else {
            Toast.fail('[1]似乎没听到声音')
            this.hideSpeechModal()
          }
        },
        fail: e => {
          Toast(JSON.stringify(['stopRecord1', e]))
          console.log(e)
          // Toast('[2]似乎没听到声音')
          this.hideSpeechModal()
        }
      })
    },
    startRecognize (localId) {
      // this.endRecognizeDelay();
      // Toast(JSON.stringify(['translateVoicelocalId', this.localId]));
      try {
        this.$wechat.translateVoice({
          localId: localId,
          // isShowProgressTips: 1, // 默认为1，显示进度提示
          success: ({ translateResult }) => {
            this.keyword = this.refineKeyword(translateResult)
            this.endRecognize()
          },
          fail: e => {
            Toast(JSON.stringify(['translateVoice2', e]))
            console.log(e)
            // Toast('[3]似乎没听到声音！')
            this.hideSpeechModal()
          }
        })
      } catch (e) {
        Toast(JSON.stringify(['translateVoice1', e]))
        console.log(e)
        // Toast('[4]似乎没听到声音！')
        this.hideSpeechModal()
      }

      // 解析超时
      this.timeoutObj = setTimeout(() => {
        Toast('语音解析超时！')
        this.hideSpeechModal()
      }, 10000)
    },
    endRecognize () {
      if (!this.visible) return
      clearTimeout(this.timeoutObj)
      this.timeoutObj = null
      this.voiceState = 'recognized'
      if (this.$route.name === 'Ai') {
        this.$eventBus.$emit('SPEECH_VOICE_KEYWORD', this.keyword)
      } else {
        this.$store.commit('speech/setSpeechRecognizedKeyword', this.keyword)
        this.$router.push({ name: 'Ai' })
      }
      // if (this.$route.name === 'Speech') {
      //   this.$eventBus.$emit('SPEECH_VOICE_KEYWORD', this.keyword)
      // } else {
      //   this.$store.commit('speech/setSpeechRecognizedKeyword', this.keyword)
      //   this.$router.push({ name: 'Speech' })
      // }
      this.hideSpeechModalDelay()
    },
    hideSpeechModal () {
      clearTimeout(this.timeoutObj)
      this.visible = false
    },
    refineKeyword (keyword) {
      return keyword.replace(/[，。,.\s]/g, '')
    }
  }
}
</script>

<style lang="less" scoped>
  .speech-modal {
    pointer-events: none;
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    .circle-wrapper {
      width: 6em;
      height: 6em;
      .voice-state {
        color: #333;
      }

      .loading{
        width: 80%;
        height: 3em;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
      .loading span{
        display: inline-block;
        width: 0.3em;
        margin: 0 0.1em;
        height: 100%;
        border-radius: 4px;
        background: #36D1DC;
        align-self: center;
        animation: load 800ms ease infinite;
      }
      @keyframes load{
        0%,100%{
          height: 60%;
          background: #36D1DC;
        }
        50%{
          height: 100%;
          background: #5B86E5;
        }
      }
      .loading span:nth-child(2){
        animation-delay:0.2s;
      }
      .loading span:nth-child(3){
        animation-delay:0.4s;
      }
      .loading span:nth-child(4){
        animation-delay:0.6s;
      }
      .loading span:nth-child(5){
        animation-delay:0.8s;
      }
    }
    .voice-loading {
      height: 6em;
      text-align: center;
      font-size: 0.75em;
      color: #888;
      line-height: 2em;
    }
    .voice-text {
      padding: 2em;
      line-height: 1.5em;
      text-align: center;
    }
    .mic-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      .voice-icon {
        font-size: 2em;
        line-height: 2em;
        color: #ccc;
      }
    }
  }
</style>
